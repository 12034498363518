import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import CareersHeader from '../../StaticQueries/CareersHeader'
import CareersGraphic from '../../StaticQueries/CareersGraphic'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


const styles = theme => ({
  paper: {
    maxWidth: 1700,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  container: {
    padding: '48px 36px 0',
  },
})

function CareersContent({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= C°A°R°E°E°R°S =-</h1> */}
            <CareersHeader/>
          </AppBar>

          <div className={classes.contentWrapper} style={{fontSize: '120%', color: 'blue'}}>
            {/* <Typography color="textPrimary" align="justify"> */}
            <typography color="textPrimary" align="justify">
              <CareersGraphic/>
              {/* <h2> */}
              <br/>
                Currently there is no active internal software development for external clients. 
                <br/>
                <br/>
                All of the projects that have been undertaken to-date were completed by the Director 
                and Principal Developer.
                However, for new software development work (anticipated client-side projects), there 
                is always the potential to utilize additional capacity and any interested parties may 
                get in touch by using the '<b>Contact</b>' page.
              {/* </h2> */}
            </typography>
            {/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

CareersContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CareersContent)
